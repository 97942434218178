@tailwind base;
@tailwind components;

.ReactModal__Body--open {
    overflow: hidden;
}
.ReactModal__Overlay {
    overflow-y: auto;
}

table.table-hover tbody tr:hover td {
    @apply bg-gray-100;
}

.form-content {
    @apply w-full bg-white px-6 pb-4;
}
.form-footer {
    @apply bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse md:rounded-b-lg;
}

/**
date picker custom
**/
.datepicker-tmg .rdrCalendarWrapper {
    @apply border rounded-md border-gray-300 mt-2;
}

.rdrMonthAndYearWrapper {
    padding-top: 0;
}

.rdrDay {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .rdrDayHovered {} */

.rdrSelected {
    position: relative;
    left: inherit;
    top: inherit;
    right: inherit;
    bottom: inherit;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    @apply bg-yellow-500;
}

.rdrDayHovered .rdrDayNumber:after {
    width: 34px;
    height: 34px;
    border-radius: 50% !important;
    top: -4px !important;
    bottom: 0 !important;
    left: 5px !important;
    right: 0px !important;
    @apply border-yellow-500 text-yellow-500;
}

.rdrDayToday .rdrDayNumber span:after {
    @apply bg-yellow-700;
}

.rdrDayEndPreview {
    display: none;
}

.rdrWeekDay {
    @apply font-medium text-gray-700;
}

.input-w-select--select button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-color: #f1f1f1;
}
.input-w-select--select-error button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-color: transparent;
    border-left-color: #f1f1f1;
}